import * as React from "react"
import { Field, Form, Formik } from "formik"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react"
import {
  ArrowBackIcon,
  CalendarIcon,
  EmailIcon,
  PhoneIcon,
} from "@chakra-ui/icons"
import { FaPaperPlane, FaUser } from "react-icons/fa"
import { Link as GatsbyLink } from "gatsby"

import { MarketingContext } from "../components/CoreUI/MarketingContext"
import { convertFormValuesToGqlQuery } from "../components/CoreUI/utils/forms"
import { validateName, validateEmail, validatePhone } from "./fieldValidators"
import FormResult from "./formResult"
import DatePicker from "./datePicker"
import {
  ApptDay,
  ApptHour,
  ApptLocation,
  ScheduleVisitFormProps,
  TimeSlot,
  ValidFormValues,
} from "./forms"
import api from "../utils/api"
import { fireGtmEvent } from "../utils/gtm"
import type CoreUI from "../components/CoreUI/types"

type FormStage = `first` | `second`

type _ValidFormValues = ValidFormValues & {
  time: string
  date: string
}

const defaultAppointmentTimes: TimeSlot[] = [
  {
    key: `Mon`,
    times: [9, 10, 11, 1, 2, 3, 4],
    location: [`Phone`],
  },
  {
    key: `Tue`,
    times: [9, 10, 11, 1, 2, 3, 4],
    location: [`Phone`],
  },
  {
    key: `Wed`,
    times: [9, 10, 11, 1, 2, 3, 4],
    location: [`Phone`],
  },
  {
    key: `Thu`,
    times: [9, 10, 11, 1, 2, 3, 4],
    location: [`Phone`],
  },
  {
    key: `Fri`,
    times: [9, 10, 11, 1, 2, 3, 4],
    location: [`Phone`],
  },
  // {
  //   key: `Sat`,
  //   times: [10, 11, 12, 1, 2, 3, 4, 5],
  //   location: [`Phone`],
  // },
]

function injectFormValues(msg: string, values: object): string {
  return Object.keys(values).reduce(
    (acc, cur) => acc.replace(`%${cur}%`, values[cur]),
    msg
  )
}

async function submitFormData(
  values: _ValidFormValues,
  actions: any,
  eventLabel
) {
  const { date, time, ...rest } = values
  const query = convertFormValuesToGqlQuery({
    ...rest,
    date_string: `${date} at ${time}`,
  })
  const { data, status } = await api.post(``, { query })
  actions.setSubmitting(false)
  actions.setStatus(status)
  fireGtmEvent({
    eventName: `form_submission`,
    eventAction: `Form Submission`,
    eventCategory: values.form_name,
    eventLabel,
  })
}

function formatPhoneNumber(fieldValue: string): string {
  const phone = fieldValue.replace(/\D/g, ``)
  let formatted = ``
  if (phone.length >= 1) {
    formatted += `(${phone.substring(0, 3)}`
  }
  if (phone.length >= 3 && fieldValue.slice(-2) !== `) `) {
    formatted += `) `
  }
  if (phone.length >= 4) {
    formatted += phone.substring(3, 6)
  }
  if (phone.length >= 6 && fieldValue.slice(-1) !== `-`) {
    formatted += `-${phone.substring(6)}`
  }
  return formatted
}

function ScheduleVisitForm({
  appointmentTimes = defaultAppointmentTimes,
  automationId = 27,
  buttonColor = `red.500`,
  buttonId = ``,
  buttonText = `Submit`,
  context = null,
  errorHeader = `Oops!`,
  errorMessage = `Something went wrong. Please try again.`,
  eventLabel = `Schedule Consult`,
  name = `Schedule Consultation`,
  successHeader = `Thanks, %name%!`,
  successMessage = `We'll contact you ASAP to confirm your appointment on %date% at %time%.`,
  syncDeal = true,
}: ScheduleVisitFormProps): JSX.Element {
  const [date, setDate] = React.useState<string>(appointmentTimes[0].key)
  const [defaultDate, setDefaultDate] = React.useState<string>()
  const [stage, setStage] = React.useState<FormStage>(`first`)
  const mkt = React.useContext<CoreUI.TMarketingContext>(MarketingContext)
  const formRef = React.useRef<HTMLFormElement>(null)
  const normalizedContext = Array.isArray(context) ? context : [context]

  const utmParams = mkt.getUtmParamsObject()
  const contextualizedValues = normalizedContext.reduce(
    (initialValues, context) => ({
      ...initialValues,
      [context.key]: context.value,
    }),
    {
      automation_id: automationId,
      date: ``,
      email: ``,
      form_name: name,
      meeting_location: `Phone`,
      name: ``,
      newsletter: false,
      phone: ``,
      sync_deal: syncDeal,
      time: `10:00 AM`,
      ...utmParams,
    }
  )

  return (
    <Formik
      initialValues={contextualizedValues}
      onSubmit={(values, actions) => {
        if (!values.date.length) {
          values.date = defaultDate
        }
        submitFormData(values, actions, eventLabel)
      }}
    >
      {props => {
        return (
          <Form ref={formRef}>
            {props.status ? (
              <Box h={formRef?.current?.clientHeight} m="auto" w="100%">
                <FormResult
                  body={injectFormValues(
                    props.status === 200 ? successMessage : errorMessage,
                    props.values
                  )}
                  flexDirection="column"
                  h="100%"
                  header={injectFormValues(
                    props.status === 200 ? successHeader : errorHeader,
                    props.values
                  )}
                  onClose={() => {
                    setStage(`first`)
                    props.setStatus(null)
                    props.resetForm()
                  }}
                  type={props.status === 200 ? `success` : `error`}
                />
              </Box>
            ) : (
              <>
                {stage === `first` && (
                  <>
                    <Field name="name" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <InputGroup size="lg">
                            <InputLeftElement>
                              <Icon as={FaUser} color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              bg="white"
                              id="name"
                              placeholder="Name"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email" validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel htmlFor="email" mt={2}>
                            Email Address
                          </FormLabel>
                          <InputGroup size="lg">
                            <InputLeftElement>
                              <EmailIcon color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              bg="white"
                              id="email"
                              placeholder="Email"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phone" validate={validatePhone}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <FormLabel htmlFor="phone" mt={2}>
                            Phone Number
                          </FormLabel>
                          <InputGroup size="lg">
                            <InputLeftElement>
                              <PhoneIcon color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              bg="white"
                              id="phone"
                              placeholder="Phone Number"
                              value={formatPhoneNumber(field.value)}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </>
                )}
                {stage === `second` && (
                  <>
                    <Field name="date">
                      {() => (
                        <>
                          <FormLabel htmlFor="date">Date</FormLabel>
                          <DatePicker
                            availableDays={appointmentTimes.map(
                              (time: TimeSlot): ApptDay => time.key
                            )}
                            onSelectDate={(date: string) => {
                              props.setFieldValue(`date`, date)
                              const dayName = date.split(`,`)[0]
                              setDate(dayName)
                            }}
                            setDefaultDate={setDefaultDate}
                          />
                        </>
                      )}
                    </Field>
                    <Box
                      alignItems="center"
                      d="flex"
                      flexDir="row"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Box w="100%">
                        <Field name="time">
                          {({ field, form }) => (
                            <FormControl isInvalid={false} isRequired>
                              <FormLabel htmlFor="time" mt={2}>
                                Time
                              </FormLabel>
                              <Select {...field} id="time" size="lg">
                                {appointmentTimes
                                  .find(({ key }) => key === date)
                                  .times.map((hour: ApptHour, i: number) => {
                                    let formattedTime = `${hour}:00 `
                                    formattedTime +=
                                      hour >= 8 && hour < 12 ? `AM` : `PM`
                                    return (
                                      <option key={i} value={formattedTime}>
                                        {formattedTime}
                                      </option>
                                    )
                                  })}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box w="59%" display="none">
                        <Field name="meeting_location">
                          {({ field, form }) => (
                            <FormControl isInvalid={false}>
                              <FormLabel htmlFor="location" mt={2}>
                                Location
                              </FormLabel>
                              <Select {...field} id="location" size="lg">
                                {appointmentTimes
                                  .find(({ key }) => key === date)
                                  .location.map(
                                    (loc: ApptLocation, i: number) => (
                                      <option key={i} value={loc}>
                                        {loc}
                                      </option>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                    </Box>
                    <Field name="newsletter">
                      {({ field }) => (
                        <FormControl isInvalid={false}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            mt={4}
                            spacing={4}
                          >
                            <Checkbox
                              {...field}
                              colorScheme="teal"
                              size="lg"
                              value="newsletter"
                            />
                            <Box textAlign="left">
                              Stay in touch with occassional updates from Sundog
                              Homes.
                            </Box>
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                  </>
                )}
                <Box
                  d="flex"
                  justifyContent="center"
                  maxW="450px"
                  mt={6}
                  w="100%"
                >
                  {stage === `second` && (
                    <Button
                      colorScheme={buttonColor.split(`.`)[0]}
                      mr={1}
                      onClick={() => setStage(`first`)}
                      size="lg"
                      variant="link"
                      w="10%"
                    >
                      <ArrowBackIcon />
                    </Button>
                  )}
                  <Button
                    colorScheme={buttonColor.split(`.`)[0]}
                    id={buttonId}
                    isDisabled={
                      stage === `first`
                        ? !props.values[`name`] || !props.values[`email`]
                        : !props.isValid || props.isSubmitting
                    }
                    isLoading={props.isSubmitting}
                    leftIcon={
                      stage === `first` ? <CalendarIcon /> : <FaPaperPlane />
                    }
                    onClick={() => {
                      if (stage === `first`) {
                        setStage(`second`)
                      } else {
                        props.handleSubmit()
                      }
                    }}
                    size="lg"
                    w={stage === `first` ? `100%` : `80%`}
                  >
                    {stage === `first` && `Select Date & Time`}
                    {stage === `second` && buttonText}
                  </Button>
                </Box>
                <Text color="gray.500" fontSize="sm" mt={4}>
                  We'll never share this information with anyone.
                  <br />
                  <Link
                    as={GatsbyLink}
                    color="gray.700"
                    to="/privacy/"
                    variant=""
                  >
                    View our privacy policy
                  </Link>
                  .
                </Text>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default ScheduleVisitForm
