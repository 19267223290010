import axios from "axios"

const api = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  headers: {
    tenant_token: process.env.GATSBY_TENANT_TOKEN,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

export default api
