import * as React from "react"
import { Box, Button, Flex, IconButton } from "@chakra-ui/react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"

import { DatePickerProps } from "./forms"

const ONE_DAY = 1000 * 60 * 60 * 24
const ONE_WEEK = ONE_DAY * 7

const CALENDAR_PAGE_W = 80
const CALENDAR_PAGE_H = 90
const CALENDAR_PAGE_X = 12

type CalendarPage = {
  ddd: string
  MMM: string
  Do: string
  YYYY: string
}

const CalendarPlaceholder: CalendarPage = {
  ddd: `Mon`,
  MMM: `Jan`,
  Do: `1st`,
  YYYY: `2021`,
}

type ScrollerValue = {
  itemsPerPage: number
  totalNumPages: number
  pagesOffScreen: number
  maxScrollX: number
}

function formatSelectedDate(date: CalendarPage): string {
  const { ddd, MMM, Do, YYYY } = date
  return `${ddd}, ${MMM} ${Do}, ${YYYY}`
}

function calcXTranslateLimiter(
  wrapperWidth: number,
  itemWidth: number,
  numItems: number
): ScrollerValue {
  const itemsPerPage = wrapperWidth / itemWidth
  const totalNumPages = numItems / itemsPerPage
  const pagesOffScreen = totalNumPages - 1
  const maxScrollX = 0 - Math.ceil(pagesOffScreen * wrapperWidth)
  return { itemsPerPage, maxScrollX, pagesOffScreen, totalNumPages }
}

function getNextValidDay(start: number, offset: number, validDays: string[]) {
  const now = start + offset
  const day = dayjs(now).format(`ddd`)
  const newOffset = offset + ONE_DAY
  if (!validDays.includes(day)) {
    return getNextValidDay(start, newOffset, validDays)
  }
  return { epoch: now, offset: newOffset }
}

function DatePicker({
  availableDays,
  buttonColor = null,
  id = ``,
  numDays = 14,
  onSelectDate,
  setDefaultDate,
  showDateString = false,
}: DatePickerProps): JSX.Element {
  dayjs.extend(advancedFormat)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [activeDate, setActiveDate] = React.useState<number>(0)
  const [dateString, setDateString] = React.useState<string>(null)
  const [scrollPos, setScrollPos] = React.useState<number>(0)
  const [dates, setDates] = React.useState<CalendarPage[]>(
    new Array(numDays).fill(CalendarPlaceholder)
  )

  // populate the date picker at runtime so the dates are always
  // referencing the current time (as opposed to the most recent
  // build time).
  React.useEffect(() => {
    function fillDatePicker(numDays: number): CalendarPage[] {
      const startTime = Date.now()
      const dateArr = [...new Array(numDays)].reduce(
        acc => {
          const offset = acc.offset || ONE_WEEK
          const thisDate = getNextValidDay(startTime, offset, availableDays)
          const date = dayjs(thisDate.epoch).format(`ddd/MMM/Do/YYYY`)
          const [ddd, MMM, Do, YYYY] = date.split(`/`)

          return {
            dates: [...(acc.dates || []), { ddd, MMM, Do, YYYY }],
            offset: thisDate.offset,
          }
        },
        [{ dates: [], offset: ONE_WEEK }]
      )
      return dateArr.dates
    }

    const dateArr = fillDatePicker(numDays)
    setDates(dateArr)
    const formattedDate = formatSelectedDate(dateArr[0])
    setDateString(formattedDate)
    setDefaultDate(formattedDate)
  }, [availableDays, numDays])

  function handleDatePick(index: number, date: CalendarPage): void {
    const formattedDate = formatSelectedDate(date)
    setActiveDate(index)
    onSelectDate(formattedDate)
    setDateString(formattedDate)
  }

  const { maxScrollX, totalNumPages } = calcXTranslateLimiter(
    containerRef?.current?.clientWidth,
    CALENDAR_PAGE_W + CALENDAR_PAGE_X,
    numDays
  )
  const scrollDistance = maxScrollX / Math.ceil(totalNumPages - 1)

  return (
    <>
      <Flex align="center" justify="space-between" w="100%">
        <IconButton
          aria-label="Show earlier dates"
          colorScheme={buttonColor ?? null}
          fontSize="2xl"
          icon={<ArrowBackIcon />}
          isDisabled={scrollPos >= 0}
          mr={1}
          onClick={() => {
            const newPos = Math.min(scrollPos - scrollDistance, 0)
            setScrollPos(newPos)
            containerRef.current.style.transition = `0.3s ease-in-out`
            containerRef.current.style.transform = `translateX(${newPos}px)`
          }}
          rounded="md"
          size="sm"
          variant="ghost"
          visibility={scrollPos >= 0 ? `hidden` : `visible`}
        />
        <Box maxW="80%" mx="auto" overflowX="hidden" w="548px">
          <Flex
            align="center"
            id={id}
            justify="start"
            m="auto"
            py="12px"
            ref={containerRef}
            transitionDuration="0.5s"
            transition="ease-in-out"
          >
            {dates.map((date: CalendarPage, index: number) => {
              const { ddd, MMM, Do } = date
              const isActiveDate = index === activeDate

              return (
                <Box key={index} mx={`${CALENDAR_PAGE_X / 2}px`}>
                  <Button
                    bg="white"
                    border="1px"
                    borderColor="gray.400"
                    display="flex"
                    flexDir="column"
                    h={`${CALENDAR_PAGE_H}px`}
                    justify="center"
                    onClick={() => handleDatePick(index, date)}
                    opacity={isActiveDate ? 1 : 0.6}
                    rounded="md"
                    shadow="md"
                    textAlign="center"
                    transform={isActiveDate ? `scale(1.125)` : `scale(1)`}
                    transition="ease-in"
                    transitionDuration="0.1s"
                    variant="unstyled"
                    w={`${CALENDAR_PAGE_W}px`}
                    _hover={{
                      opacity: 1,
                      shadow: `lg`,
                      transform: `scale(1.1)`,
                    }}
                  >
                    <Box fontSize="sm">{ddd}</Box>
                    <Box fontSize="xl" fontWeight="extrabold">
                      {Do}
                    </Box>
                    <Box
                      fontSize="xs"
                      fontWeight="light"
                      textTransform="uppercase"
                    >
                      {MMM}
                    </Box>
                  </Button>
                </Box>
              )
            })}
          </Flex>
        </Box>
        <IconButton
          aria-label="Show later dates"
          colorScheme={buttonColor ?? null}
          fontSize="2xl"
          icon={<ArrowForwardIcon />}
          isDisabled={scrollPos <= maxScrollX}
          ml={1}
          onClick={() => {
            const newPos = Math.max(scrollPos + scrollDistance, maxScrollX)
            setScrollPos(newPos)
            containerRef.current.style.transition = `0.3s ease-in-out`
            containerRef.current.style.transform = `translateX(${newPos}px)`
          }}
          rounded="md"
          size="sm"
          variant="ghost"
          visibility={scrollPos <= maxScrollX ? `hidden` : `visible`}
        />
      </Flex>
      {showDateString && (
        <Box
          bg="gray.50"
          maxW="80%"
          mx="auto"
          p={2}
          rounded="lg"
          textAlign="center"
          w="548px"
        >
          {dateString}
        </Box>
      )}
    </>
  )
}

export default DatePicker
