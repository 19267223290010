export const convertFormValuesToGqlQuery = (values, mutation, selectors) => {
  const DEFAULT_MUTATION = `triggerActiveCampaignAutomations`
  const DEFAULT_SELECTORS = [`error`, `result`]

  const queryStart = `mutation{\n  ${mutation ?? DEFAULT_MUTATION}(`
  let query = Object.keys(values).reduce((gqlQuery, key, i) => {
    if (i > 0) gqlQuery += `,`
    return `${gqlQuery}\n    ${key}:"${values[key]}"`
  }, queryStart)
  query += `\n  ) {\n`

  const _selectors = selectors ?? DEFAULT_SELECTORS
  query = _selectors.reduce(
    (gqlQuery, selector) => `${gqlQuery}    ${selector}\n`,
    query
  )

  query += `  }\n}`
  return query
}
