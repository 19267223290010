type ValidatorProps = {
  value: string
}

export function validateName(name: string): string {
  if (!name) {
    return `We need to know what to call you!`
  }
  return ``
}

export function validateEmail(email: string): string {
  if (!email) {
    return `Oops — an email address is required.`
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return `Hmm... This doesn't look like a valid email.`
  }
  return ``
}

export function validatePhone(phone: string): string {
  if (phone) {
    const strippedNumber = phone.replace(/[^0-9]/g, ``)
    if (strippedNumber.length < 10) {
      return `Hmm... This appears to be missing some digits`
    } else if (strippedNumber.length > 10) {
      return `Hmm... This appears to have too many digits.`
    } else {
      if (/^(\d)\1+$/g.test(strippedNumber)) {
        return `Nice try ;)`
      }
    }
  }
  return ``
}
